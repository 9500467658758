<template>
  <div class="position"></div>

  <div class="banner">
    <img
      class="banner__img"
      src="https://sxupdatefile.oss-ap-southeast-1.aliyuncs.com/image/guanggaoE.jpg"
    />
  </div>
  <div class="icons">
    <router-link
      class="icons__item"
      v-for="item in iconsList" :key="item.desc"
      :to="item.url"
    >
    <img
        class="icons__item__img"
        :src="`https://sxlogin.oss-cn-shenzhen.aliyuncs.com/vimg/${item.imgName}`"
      />
    <p class="icons__item__desc">{{ item.desc }}</p>
    </router-link>

   
  </div>
  <div class="gap"></div>
</template>

<script>
export default {
  name: "StaticPart",
  setup() {
    const iconsList = [
      { imgName: "up.png", desc: "主题市场" ,url:'uploadlist'},
      { imgName: "jc.png", desc: "测试版" ,url:'videolist'},
      { imgName: "shuoming.png", desc: "说明书" ,url:'instructions'},
      { imgName: "kf.png", desc: "客服" ,url:'kf'},
    ];
    return { iconsList };
  },
};
</script>

<style lang="scss" scoped>
@import "../../style/viriables.scss";
@import "../../style/mixins.scss";
.position {
  position: relative;
  padding: 0.16rem 0.24rem 0.16rem 0;
  line-height: 0.22rem;
  font-size: 0.16rem;
  @include ellipsis;
  .position__icon {
    position: relative;
    top: 0.01rem;
    font-size: 0.2rem;
  }
  .position_notice {
    position: absolute;
    right: 0;
    top: 0.17rem;
    font-size: 0.2rem;
  }
  color: $content-fontcolor;
}
.search {
  margin-bottom: 0.12rem;
  line-height: 0.32rem;
  background: $search-bgColor;
  color: $search-fontColor;
  border-radius: 0.16rem;
  .iconfont {
    display: inline-block;
    padding: 0 0.08rem 0 0.16rem;
    font-size: 0.16rem;
  }
  &__text {
    display: inline-block;
    font-size: 0.14rem;
  }
}
.banner {
  height: 0;
  overflow: hidden;
  padding-bottom: 25.4%;
  &__img {
    width: 100%;
  }
}
.icons {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.16rem;
  &__item {
    width: 25%;
    &__img {
      display: block;
      margin: 0 auto;
      width: 0.4rem;
      height: 0.4rem;
      
    }
    &__desc {
      margin: 0.06rem 0 0.16rem 0;
      text-align: center;
      color: $content-fontcolor;
    }
  
  }a {
    text-decoration: none;
  }
}
.gap {
  margin: 0 -0.18rem;
  height: 0.1rem;
  background: $content-bgColor;
}
</style>