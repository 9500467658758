<template>
  <div class="nearby">
    <h5 class="nearby__title">图片更新：</h5>
    <el-upload
      class="upload-demo nearby__image"
      action="https://image.yihisxminiid.com/api/upbin/upimg"
      :data="token"
      :on-preview="handlePreview"
      :on-remove="handleRemove"
      :on-success="handlesuccess"
      :file-list="fileList"
      :limit="1"
      list-type="picture"
    >
      <el-button size="small" type="primary">上传图片</el-button>
      <template #tip>
        <div class="el-upload__tip">选中即上传,只能上传 jpg/png 文件,且不超过 500kb</div>
      </template>
    </el-upload>
  </div>
</template>

<script>


export default {
  data() {
    return {
      fileList: [],
      token:{token:sessionStorage.token},
    };
  },
  methods: {
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handlesuccess(file){
      console.log(file)
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../../style/viriables.scss";
.nearby {
  &__title {
    margin: 0.16rem 0 0.02rem 0;
    font-size: 0.13rem;
    font-weight: bold;
    color: $content-fontcolor;
  }
  &__image {
    margin: 0.16rem 0 0.02rem 0;
  }
}
</style>


